import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'

import Header from './header'
import '../assets/css/layout.scss'
import '../assets/css/layout-app.scss'

const Layout = ({children, data}) => (
  <StaticQuery query={
      graphql `
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
    }
    render={
      data => (
        <>
          <Helmet title={
              data.site.siteMetadata.title
            }
            meta={
              [
                {
                  name: 'description',
                  content: 'Sample'
                }, {
                  name: 'keywords',
                  content: 'sample, something'
                },
              ]
          }>
            <html lang="en"/>
          </Helmet>
          <Header siteTitle={
            data.site.siteMetadata.title
          }/>


          <main class="main-body bb-5">
            
              {children} 

          </main>
        </>
      )
    }/>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
