import React from 'react'
import { Link, navigate } from 'gatsby'
// import { navigate } from '@reach/router'

import { logout, isLoggedIn } from "../utils/auth"
import { Auth } from 'aws-amplify'

const Header = ({ siteTitle }) => (
  // <div
  //   style={{
  //     background: 'rebeccapurple',
  //     marginBottom: '1.45rem',
  //   }}
  // >
  //   <div
  //     style={{
  //       margin: '0 auto',
  //       maxWidth: 960,
  //       padding: '1.45rem 1.0875rem',
  //     }}
  //   >
  //     <h1 style={{ margin: 0 }}>
  //       <Link
  //         to="/"
  //         style={styles.headerTitle}
  //       >
  //         {siteTitle}
  //       </Link>
  //     </h1>
  //     {
  //       isLoggedIn() && (
  //         <p
  //           onClick={
  //             () => Auth.signOut().then(logout(() => navigate('/cp/login'))).catch(err => console.log('eror:', err))
  //           }
  //           style={styles.link}
  //         >Sign Out</p>
  //       )
  //     }
  //   </div>
  // </div>

  <nav class="container-fluid navbar navbar-expand-md navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand ci-logo" href="https://www.charitableimpact.com"><img
                src="https://cdn2.hubspot.net/hubfs/2004091/Template-Kits/landing-pages/images/logo-ci-colour-2x.png"
                alt="Charitable Impact" /></a>


        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto">
                {/* <li class="nav-item d-flex justify-content-center">
                    <a class="nav-link" href="https://my.charitableimpact.com/users/login">Log in</a>
                </li> */}
                <li class="nav-item d-flex justify-content-center">
                    <a class="nav-link btn btn-primary btn-signup" href="/cp/home">Affiliate Login</a>
                </li>
            </ul>
        </div>

      </div>


      </nav>
)

const styles = {
  headerTitle: {
    color: 'white',
    textDecoration: 'none',
  },
  link: {
    cursor: 'pointer',
    color: 'white',
    textDecoration: 'underline'
  }
}

export default Header
